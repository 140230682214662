/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
/* eslint-disable global-require */
import axios from 'axios';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import React, { useEffect, useState, useContext } from 'react';
import { Col, Row, Image, Container } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SpinnerDotted } from 'spinners-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import { getCookies } from '../../hooks/useCookies';
import { TableContext } from '../../contexts/tableContext';
import styles from './viewUser.module.scss';
import { DateFormatter } from '../../utilityFunctions/utilsFunctions';
import envValues from '../../enviornment';
import logoimage from '../../assets/user_iconadmin.png';
import LoaderIcon from '../../components/UploadLoader/Loader';

function ViewUsers() {
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { viewformStyle } = useContext(TableContext);
  const params = useParams();
  let userId;
  const token = `Bearer ${getCookies('Token')}`;
  const [loading, setLoading] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [loadSheet, setLoadSheet] = useState(false);
  const [details, setDetails] = useState([]);
  const [detailsEmployer, setDetailsEmployer] = useState([]);
  const [employerValues, setemployerValues] = useState({});
  const [roles, setRole] = useState([]);
  const dateFormat = getCookies('dateFormat');
  let statusCode;
  const getUserDetails = async () => {
    setLoading(true);
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/user/${userId}`, {
        method: 'GET',
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp.data;
      })
      .then((res) => {
        if (statusCode === 200) {
          setDetails(res.data.user);
          setDetailsEmployer(res.data.employerDetails);
          setRole(res.data.user.role);
          setemployerValues(res.data.employerValues[0]);
          setDashboardHeader(
            `${
              res.data.user.role.name.charAt(0).toUpperCase() +
              res.data.user.role.name.slice(1).toLowerCase()
            } Details`
          );
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  const getEmployeeReport = async (id) => {
    setReportLoading(true);
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/user/employee-report/${id}`, {
        method: 'GET',
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp.data;
      })
      .then((res) => {
        if (statusCode === 200) {
          toast.success('The reports has been sent to your email.');
          setReportLoading(false);
        } else {
          setReportLoading(false);
        }
      })
      .catch((err) => {
        setReportLoading(false);
        return err;
      });
  };

  const handleSheetUpload = async (event, id) => {
    setLoadSheet(true);
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('File', file);

    try {
      const response = await axios.post(
        `${envValues.REACT_APP_API_ENDPOINT}/user/upload-sheet/${id}?url=${window.location.href}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${getCookies('Token')}`,
          },
        }
      );
      if (response.data.code === 200) {
        toast.success('Employees are created.');
        setLoadSheet(false);
      } else {
        confirmAlert({
          message: response.data.data,
          buttons: [
            {
              label: 'Ok',
              // onClick: () => alert("Click No")
            },
          ],
        });
        setLoadSheet(false);
      }
      event.target.value = '';
    } catch (error) {
      setLoadSheet(false);
    } finally {
      setLoadSheet(false);
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    userId = params.userId;
    getUserDetails();
  }, []);
  const capitalizeFirstLetter = (string) => {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
    return 'N/A';
  };
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ height: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          {loading ? (
            <SpinnerDotted
              style={{
                color: '#39979d',
                left: '50%',
                position: 'relative',
                textAlign: 'center',
                top: '50%',
              }}
            />
          ) : (
            <Container fluid>
              <FontAwesomeIcon
                icon={faArrowLeft}
                value="Back"
                onClick={() => {
                  navigate(-1);
                }}
                className={styles.arrowback}
              />
              <Row>
                <Col lg={12}>
                  <div className={styles.profilepicdiv}>
                    {details?.image ? (
                      <Image
                        src={details?.image}
                        alt="Profile Image"
                        className={styles.profilepic}
                      />
                    ) : (
                      <Image src={logoimage} alt="Profile Image" className={styles.profilepic} />
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg={12} md={12} sm={12}>
                  <div className={styles.detaildiv}>
                    <Row>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Name</span>
                          <p className={styles.empname}>{details.name}</p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Email</span>
                          <p className={styles.empname}>{details.email}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {roles.name === 'user' ? (
                        <Col lg={4}>
                          <div className={styles[viewformStyle]} id={styles.empdiv}>
                            <span className={styles.title}>Company Name</span>
                            <p className={styles.empname}>
                              {detailsEmployer.companyName ? detailsEmployer.companyName : 'N/A'}
                            </p>
                          </div>
                        </Col>
                      ) : (
                        <Col lg={4}>
                          <div className={styles[viewformStyle]} id={styles.empdiv}>
                            <span className={styles.title}>Company Name</span>
                            <p className={styles.empname}>
                              {details.companyName ? details.companyName : 'N/A'}
                            </p>
                          </div>
                        </Col>
                      )}
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Role</span>
                          <p className={styles.empname}>{capitalizeFirstLetter(roles.name)}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Joined Date</span>
                          <p className={styles.empname}>
                            {details?.createdAt
                              ? DateFormatter(details.createdAt, dateFormat || 'toDateString')
                              : 'N/A'}
                          </p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Status</span>
                          <p className={styles.empname}>{details.active ? 'Active' : 'InActive'}</p>
                        </div>
                      </Col>
                    </Row>
                    {details.userType === 'user' ? (
                      <Row>
                        <Col lg={4}>
                          <div className={styles[viewformStyle]} id={styles.empdiv}>
                            <span className={styles.title}>Plan</span>
                            <p className={styles.empname}>
                              {detailsEmployer.subscription === 'Freemium'
                                ? 'Expired'
                                : detailsEmployer.subscription}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className={styles[viewformStyle]} id={styles.empdiv}>
                            <span className={styles.title}>Subscribed Date</span>
                            <p className={styles.empname}>
                              {detailsEmployer?.subscriptionDate
                                ? DateFormatter(
                                    detailsEmployer.subscriptionDate,
                                    dateFormat || 'toDateString'
                                  )
                                : 'N/A'}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col lg={4}>
                          <div className={styles[viewformStyle]} id={styles.empdiv}>
                            <span className={styles.title}>Plan</span>
                            <p className={styles.empname}>
                              {details.subscription === 'Freemium'
                                ? 'Expired'
                                : details.subscription}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className={styles[viewformStyle]} id={styles.empdiv}>
                            <span className={styles.title}>Subscribed Date</span>
                            <p className={styles.empname}>
                              {details?.subscriptionDate
                                ? DateFormatter(
                                    details.subscriptionDate,
                                    dateFormat || 'toDateString'
                                  )
                                : 'N/A'}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    )}
                    {roles.name === 'user' ? (
                      <Row>
                        <Col lg={4}>
                          <div className={styles[viewformStyle]} id={styles.empdiv}>
                            <span className={styles.title}>Employer</span>
                            <p className={styles.empname}>{detailsEmployer.name}</p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className={styles[viewformStyle]} id={styles.empdiv}>
                            <span className={styles.title}>BALANCE Score</span>
                            <p className={styles.empname}>
                              {details.burnoutscore +
                                details.selfcare_point +
                                details.wellbeingscore +
                                details.workplacehealth_point}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col lg={4}>
                          <div className={styles[viewformStyle]} id={styles.empdiv}>
                            <span className={styles.title}>Total users</span>
                            <p className={styles.empname}>
                              {employerValues?.totalUsers ? employerValues?.totalUsers : '0'}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className={styles[viewformStyle]} id={styles.empdiv}>
                            <span className={styles.title}>Average BALANCE Score</span>
                            <p className={styles.empname}>
                              {isNaN(employerValues?.avgBalancer)
                                ? 'N/A'
                                : Number(employerValues?.avgBalancer).toFixed(2)}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    )}
                    {roles.name === 'user' && (
                      <>
                        <Row>
                          <Col lg={4}>
                            <div className={styles[viewformStyle]} id={styles.empdiv}>
                              <span className={styles.title}>Burnout Score</span>
                              <p className={styles.empname}>
                                {details.burnoutscore === 0
                                  ? details.burnoutscore
                                  : `${details.burnoutscore}`}
                              </p>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className={styles[viewformStyle]} id={styles.empdiv}>
                              <span className={styles.title}>Age</span>
                              <p className={styles.empname}>{details.age ? details.age : 'N/A'}</p>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          {detailsEmployer.isSubscribed && (
                            <Col lg={4}>
                              <div className={styles[viewformStyle]} id={styles.empdiv}>
                                <span className={styles.title}>Expiry Date</span>
                                <p className={styles.empname}>
                                  {detailsEmployer?.subscriptionEndDate
                                    ? DateFormatter(
                                        detailsEmployer.subscriptionEndDate,
                                        dateFormat || 'toDateString'
                                      )
                                    : 'N/A'}
                                </p>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </>
                    )}
                    {roles.name === 'employer' && (
                      <>
                        <Row>
                          <Col lg={4}>
                            <div className={styles[viewformStyle]} id={styles.empdiv}>
                              <span className={styles.title}>Average Burnout Score</span>
                              <p className={styles.empname}>
                                {isNaN(employerValues?.avgBurnoutScore)
                                  ? 'N/A'
                                  : employerValues?.avgBurnoutScore >= 0
                                  ? Number(employerValues?.avgBurnoutScore).toFixed(2)
                                  : `${Number(employerValues?.avgBurnoutScore).toFixed(2)}`}
                              </p>
                            </div>
                          </Col>
                          {details.isSubscribed && (
                            <Col lg={4}>
                              <div className={styles[viewformStyle]} id={styles.empdiv}>
                                <span className={styles.title}>Expiry Date</span>
                                <p className={styles.empname}>
                                  {details?.subscriptionEndDate
                                    ? DateFormatter(
                                        details.subscriptionEndDate,
                                        dateFormat || 'toDateString'
                                      )
                                    : 'N/A'}
                                </p>
                              </div>
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <div className={styles.btnclass}>
                              <button
                                className={styles.formbtn}
                                onClick={() => getEmployeeReport(details.id)}
                                disabled={reportLoading}
                              >
                                {reportLoading ? 'Please wait...' : 'Get Reports'}
                              </button>
                              {details?.isSubscribed && (
                                <div>
                                  <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: 'none' }}
                                    accept=".xlsx"
                                    onChange={(e) => handleSheetUpload(e, details?.id)}
                                  />
                                  <button
                                    className={styles.uplbtn}
                                    onClick={() => document.getElementById('fileInput').click()}
                                    disabled={loadSheet}
                                  >
                                    {loadSheet ? 'Please wait...' : 'Bulk Upload Employees'}
                                  </button>
                                  <div className={styles.tooltipIcon} id="tooltip">
                                    !
                                  </div>
                                  <Tooltip openOnClick anchorSelect="#tooltip" clickable>
                                    <div>
                                      <p>
                                        <strong>Bulk Upload Guidelines:</strong>
                                      </p>
                                      <ol>
                                        <li>
                                          Use the{' '}
                                          <a
                                            href="https://docs.google.com/spreadsheets/d/1EroajF-8QGTnOf967nD91dRo6BnzfXVRnkRDX2l7c3M/edit?gid=0#gid=0"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            sample template
                                          </a>{' '}
                                          for uploads.
                                        </li>
                                        <li>Names must be 3 to 32 alphabetic characters.</li>
                                        <li>Ensure the email address is valid.</li>
                                        <li>
                                          Create a password with at least:
                                          <ul>
                                            <li>8 characters</li>
                                            <li>One uppercase letter</li>
                                            <li>One lowercase letter</li>
                                            <li>One special character (!@#$%^&*)</li>
                                            <li>One number</li>
                                          </ul>
                                        </li>
                                        <li>Avoid duplicate email entries.</li>
                                        <li>Including existing emails will result in errors.</li>
                                        <li>The file format supported is only .xlsx.</li>
                                        <li>
                                          Uploading a file with incorrect column headers will result
                                          in an error.
                                        </li>
                                        <li>
                                          Do not close the tab or click other links during the
                                          upload; wait for it to complete.
                                        </li>
                                      </ol>
                                    </div>
                                  </Tooltip>
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          )}
          {loadSheet && <LoaderIcon />}
        </div>
      </div>
    </div>
  );
}

export default ViewUsers;
