/* eslint-disable react/function-component-definition */
import React, { useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import styles from './index.module.scss';
import CustomTable from '../../components/CustomTable/CustomTable';
import { REACT_APP_API_FETCH_EMPOLYEE } from '../../redux/apiConstants';
import { TableContext } from '../../contexts/tableContext';

const EmpolyeeUserIndex = React.memo(() => {
  const params = useParams();
  const { setPageNumView, pageNumViewEmployer } = useContext(TableContext);
  console.log(pageNumViewEmployer);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className={styles.cstm_back}>
        <FontAwesomeIcon
          icon={faArrowLeft}
          value="Back"
          onClick={() => {
            setPageNumView(pageNumViewEmployer);
            navigate(-1);
          }}
          className={styles.arrowback}
        />
      </div>
      <div className={styles.indexdiv}>
        <CustomTable
          urlParam={`${REACT_APP_API_FETCH_EMPOLYEE}/${params.userId}?`}
          // viewPopUp
          title="Employees"
          section="User"
          toggleButton={{ show: true, field: 'active' }}
          deleteCondition={{ checkCondition: false, apiUrl: 'www.google.com' }}
        />
      </div>
    </>
  );
});
export default EmpolyeeUserIndex;
